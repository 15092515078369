<template>
    <div class="userset">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    个人信息
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='user_content'>
            <div class='user_list'>
                <div>
                    头像
                </div>
                <input type="file" accept="image/*" name="file" class='input_file' @change='filechange' id='file'>
                <img :src='user.userHeadPicUrl?changetText(user.userHeadPicUrl):"https://static.yihu365.cn/img/h5Img/assets/img/my/user.png"' class='user_icon'/>

            </div>
            <div class='user_list'>
                <div>
                    昵称
                </div>
                <!-- <div>{{user.nickName}}</div> -->
                <van-field v-model="user.nickName" class='input_text' placeholder="您的昵称" input-align="right"/>
            </div>
            <div class='user_list'>
                <div>
                    手机号
                </div>
                <!-- <div>{{user.userMobile}}</div> -->
                <van-field v-model="user.userMobile" class='input_text' placeholder="手机号码" input-align="right" :disabled='abelTag'/>
            </div>
             <div class='user_list'>
                <div>
                    性别
                </div>
                <van-radio-group v-model="user.userSex" direction="horizontal" :disabled='abelTag'>
                    <van-radio name="0" checked-color="#00C291">男</van-radio>
                    <van-radio name="1" checked-color="#00C291">女</van-radio>
                </van-radio-group>

            </div>
            <div class='user_list'>
                <div>
                    真实姓名
                </div>
                <!-- <div>{{user.userRealName}}</div> -->
                <van-field v-model="user.userRealName" class='input_text' placeholder="真实姓名" input-align="right" :disabled='abelTag'/>
            </div>
            <div class='user_list'>
                <div>
                    身份证号
                </div>
                <!-- <div>{{user.userIdCardNo}}</div> -->
                <van-field v-model="user.userIdCardNo" class='input_text' placeholder="身份证号" input-align="right" :disabled='abelTag'/>
            </div>
            <div class='user_list changelist'>
                <div>
                    身高
                </div>

                <van-field v-model="user.height" class='input_text' placeholder="身高" input-align="right" type='number'/>
            </div>
            <div class='user_list changelist'>
                <div>
                    体重
                </div>

                <van-field v-model="user.weight" class='input_text' placeholder="体重" input-align="right" type='number'/>
            </div>

        </div>

        <div class='btn_cont mt30'>

            <van-button type="primary" block round color='#00C291' @click='save'>保存</van-button>
            <van-button type="default" block round  @click='logout' style='margin-top:10px'>退出登录</van-button>
        </div>

    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog,Toast } from 'vant'
import loginApi from '@axios/login'


export default {
  name: 'userset',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let checked = ref("1")
    let abelTag = ref(false);

    let user = ref({
        userHeadPicUrl : ''
    });

    const getUserSet = () => {
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'getUserInfo1',
            userId : store.state.uid,
            _from : 'h5'
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            user.value = res.user;
            if(res.user.nickName){
                abelTag.value = true;
            }
            console.log(user.value)
        })
    }

    const save = () => {
      let nickName =user.value.nickName.replace(/[\t\r\f\n\s]*/g,'');//去除所有空格
      const reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
      console.log(nickName)
      if(!reg.test(nickName)){
        Toast("昵称请输入汉字或字母或数字或组合");
        return;
      }

        let formData = new FormData();

        if (parseInt(user.value.height) > 300 || parseInt(user.value.height) <= 10) {
            Toast("身高不正确");
            return;
        }
        if (parseInt(user.value.weight) > 300 || parseInt(user.value.weight) <= 10) {
            Toast("体重不正确");
            return;
        }

        formData.append('data',JSON.stringify({
            function : 'updateUserInfo',
            userid : store.state.uid,
            nickName : nickName,
            userHeadPicUrl : user.value.userHeadPicUrl,
            userSex : user.value.userSex,
            userRealName1 : nickName,
            userRealName : user.value.userRealName,
            userIdCardNo : user.value.userIdCardNo,
            height : user.value.height,
            weight : user.value.weight,
            _from : 'h5',
        }));
        formData.append('encryption',false);
        loginApi.loginAction(formData).then((res)=>{
            Toast('保存成功');
            setTimeout(()=>{
                router.back();
            },1000)
        })
    }

    // const radiochange = (val) =>{
    //     console.log(12)
    //     if(user.value.nickName){
    //         if(val == '0'){
    //             user.value.userSex = '1'
    //         }else{
    //             user.value.userSex = '0'
    //         }
    //     }
    // }

    const filechange = (val) => {

        let file = document.getElementById('file').files[0];
        let type = file.type.split('/')[1];
        console.log(type)
        if(['jpg','jpeg','png','git'].indexOf(type) < 0){
            Toast('图片格式不正确');
            return;
        }

        if(file.size > 1024 * 1024 * 10){
            Toast('图片过大');
            return;
        }

        let formData = new FormData();

        formData.append('file',file);



        loginApi.uploadAction(formData).then((res)=>{
            user.value.userHeadPicUrl = res.message;
        })

    }


    getUserSet();

    let itemref = []
    const toref = (el) => {
      itemref.push(el)
    }

    const changetText = (val) => {
        var reg = RegExp(/http/);
        if(reg.test(val)){
            return 'https://' + val.split('//')[1]
        }else{
            return 'https://file.yihu365.cn' + val
        }
    }


    const logout = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        store.commit('setToken', '');
        store.commit('setUid', '');
        setTimeout(()=> {
          router.push({
            name : 'home'
          })
        },500)

    }

    return {
        logout,
        abelTag,
        changetText,
        toref,
        filechange,
        // radiochange,
        save,
        user,
        checked
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .input_file{
        width: 100px;
        height: 100px;
        opacity: 0;
        position: absolute;
        right: 20px;
        top:20px;

    }
    .user_content{
        padding:0 45px;
    }
    .userset{
        background: #fff;
        padding-bottom: 20px;
    }
    .user_list{
        padding:20px 20px;
        border-bottom: 1px solid #EDEDED;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 34px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        position: relative;
        img{
            width: 100px;
            height: 100px;
            border-radius:50px;
        }
        ::v-deep(.van-field__control){
            color:#000!important;
            -webkit-text-fill-color:#000!important;
        }
    }
    .input_text{
        width: 400px;
        text-align: right;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
        padding-right:0;
    }


</style>
